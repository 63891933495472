import React, { useEffect } from 'react';
import Layout from '../components/commons/Layout';
import styled from 'styled-components';
import Container from '../components/layout/Container';
import ResponsiveText from '../components/typography/ResponsiveText';
import theme from '../constants/theme';
import { graphql } from 'gatsby';

const Wrapper = styled.section`
  margin-top: 120px;
  margin-bottom: 120px;

  h2 {
    margin-bottom: 40px;
  }

  .container {
    flex-wrap: wrap;
  }

  .markdown {
    font-size: 21px;

    h3 {
      font-family: ${theme.fontNeue};
      font-size: 64px;
      line-height: 74px;
      text-transform: none;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 60px;
    margin-bottom: 60px;

    h2 {
      margin-bottom: 20px;
    }

    .markdown {
      h3 {
        font-size: 32px;
        line-height: 45px;
      }
    }
  }
`;

export default function Cookie({ data }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://consent.cookiebot.com/d1548343-8198-49e4-8975-b61c592cbaaf/cd.js';
    script.async = true;
    document.querySelector('#cookiePolicy')?.appendChild(script);

    return () => {
      document.querySelector('#cookiePolicy')?.removeChild(script);
    };
  }, []);

  return (
    <Layout>
      <Wrapper data-scroll-section data-scroll>
        <Container>
          <ResponsiveText type="bigger">
            <h2>Cookies</h2>
          </ResponsiveText>
          <main id="cookiePolicy"></main>
        </Container>
      </Wrapper>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
